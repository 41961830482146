/* You can add global styles to this file, and also import other style files */
//@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
//@import "~bootstrap/dist/css/bootstrap.css";
//Size:0.1Mb
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
//Size:?Mb
//@import "~highlight.js/styles/googlecode.css";
//@import "~perfect-scrollbar/css/perfect-scrollbar.css";
//Size:0.3Mb
// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";
//Size:7Mb
// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";
// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";
//Size:0.2Mb
// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";

.header {
  background-color: #ffffff;

  // Fixed header
  .header-fixed & {
    box-shadow: 0 0 40px 0 rgba(82, 63, 105, 0.1);;
  }
}
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

//Size:?Mb
// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }
// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }
// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

//Size:0Mb
// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

//Size:?Mb
// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }
// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

//Size:1Mb
//@import "assets/rappid/rappid.css";
//Size:0Mb
//@import "./css/style.css";
//@import "./css/style.modern.css";
//@import "./css/style.dark.css";
//@import "./css/theme-picker.css";

$danger-light: #ffe1e8;
$danger: hsl(1, 78%, 65%);
$danger2: hsl(1, 78%, 58%);

//new proj
//disable svg title
//Size:0.1Mb (below all custom css)

@media (min-width: 992px) {
  .card .card-body:first-child {
    padding: 20px 0 !important;
  }
}

@media (max-width: 500px) {
  .mat-form-field-flex {
    max-width: 140px;
  }
}

@media (max-width: 400px) {
  .mat-form-field-flex {
    max-width: 100px;
  }
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0 !important;
}

.mat-select-arrow-wrapper {
  margin-top: 13px;
  padding-top: 13px;
}

.mat-form-field-infix {
  padding: 0;
  margin: -4px 0px -4px 3px;
}

.mat-form-field-flex {
  height: 28px
}

.min-h-200px {
  min-height: 200px;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3F4254;
  background-color: #F3F6F9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.42rem;
}

.btn.btn-light {
  color: #7E8299;
  background-color: #EBEDF3;
  border-color: #EBEDF3;
}

.btn.btn-light-primary {
  color: #3699FF;
  background-color: #E1F0FF;
  border-color: transparent;
}

.btn.btn-hover-secondary-bk:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-secondary-bk:focus:not(.btn-text), .btn.btn-hover-secondary-bk.focus:not(.btn-text) {
  //color: #3F4254 !important;
  background-color: #D1D3E0 !important;
  border-color: #D1D3E0 !important;
}

.btn.btn-hover-secondary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-secondary:focus:not(.btn-text), .btn.btn-hover-secondary.focus:not(.btn-text) {
  color: #3F4254 !important;
  background-color: #D1D3E0 !important;
  border-color: #D1D3E0 !important;
}

.btn:hover:not(.btn-text), .btn:focus:not(.btn-text), .btn.focus {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.btn.btn-light .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #7E8299;
}

.btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-light-primary:focus:not(.btn-text), .btn.btn-light-primary.focus:not(.btn-text) {
  color: #FFFFFF;
  background-color: #3699FF;
  border-color: transparent;
}

.btn:hover:not(.btn-text), .btn:focus:not(.btn-text), .btn.focus {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}


.btn.btn-hover-secondary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill], .btn.btn-hover-secondary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-hover-secondary.focus:not(.btn-text) .svg-icon svg g [fill] {
  transition: fill 0.3s ease;
  fill: #3F4254 !important;
}

.icon-xs {
  font-size: 0.65rem !important;
}
svg {
  pointer-events: none;
}

.text-dark-50 {
  color: #7E8299 !important;
}

.text-dark-75 {
  color: #3F4254 !important;
}
.m-portlet__body {
  svg {
    pointer-events: auto;
  }
}


.card-body {
  .card-body {
    padding: 20px 0;
  }

  .mat-expansion-panel-body {
    //padding: 15px;
  }
}

//Mobile
@media (max-width: 992px) {
  a.btn {
    margin-left: 0 !important;
  }
  .card {
    .card-body {
      padding: 0 20px !important;
    }

    .card-body:first-child {
      padding: 20px 0 !important;
    }

    > .card-header {
      padding: 0 12px
    }
  }
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

@media (max-width: 576px) {
  .card {
    .card-body {
      padding: 0 10px !important;
    }
  }
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}


// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}

.angular-bootstrap-table {
  th {
    outline: none;
    padding-right: 20px;
    position: relative;


    app-sort-icon {
      top: 40%;
      right: 0;
      position: absolute;
    }

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: red !important;
    //color: $primary !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.progress-modal {
  height: 3px;
  width: 100%;
}

//old proj

@-moz-document url-prefix() {
  html {
    font-size: 18px;
  }
  div {
    font-size: 15px;
  }
}

.bb-black {
  border-top: 2px solid #e7ecf1;
  border-bottom: 2px solid #e7ecf1;
  background-color: gainsboro;
}

.m-body .m-content {
  height: 100%;
  background-color: unset;
}

.fade-in {
  -webkit-animation: fadeInUp .5s;
  animation: fadeInUp .5s;
}

.form-control-feedback {
  width: 120px;
}

.page-content {
  font-family: Microsoft JhengHei;
}

.popover-title {
  color: black !important;
}

table.dataTable.no-footer {
  margin-bottom: 0 !important;
}

// toastr
.toast-top-right {
  top: 90px !important;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd342f !important;
}

.toast-info {
  background-color: #2f96b4 !important;
}

.toast-warning {
  background-color: #f89406 !important;
}

.tabs-left.nav-tabs > li {
  width: 100%;
}

.page-editing table[border="0"] > tbody > tr > td {
  border: 1px dotted;
  border-color: grey !important;
}

.ha-finger {
  cursor: pointer !important;
}

// bug fix for non display of bootstrap modal
.fade {
  opacity: 0;
}

.fade.show, .fade.in {
  opacity: 1;
}

// success/danger button

.btn-success {
  color: #fff !important;
  background-color: #36c6d3 !important;
  border-color: #2bb8c4 !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #e7626d !important;
  border-color: #e7626d !important;
}

.btn-danger:disabled {
  color: #fff !important;
  background-color: #ffd1d5 !important;
  border-color: #ffccce !important;
  cursor: auto;
}

.btn-success.active, .btn-success:active, .btn-success:hover, .open > .btn-success.dropdown-toggle {
  color: #fff !important;
  background-color: #27a4b0 !important;
  border-color: #208992 !important;
}

.btn-danger.active, .btn-danger:active, .btn-danger:not([disabled]):hover, .open > .btn-danger.dropdown-toggle {
  color: #fff !important;
  background-color: $danger2 !important;
  border-color: $danger2 !important;
}

// datatable pagination
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination > li > a:hover, .pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eeeeee;
  border-color: #ddd;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

// modal
.modal-lg {
  max-width: 1000px;
}

//#kt_body .modal-lg {
//  max-width: none;
//}

#kt_body .modal-dialog {
  //margin: 15px;
}

// jquery-ui
.ui-button-icon-only {
  text-indent: 0;
}

// angular file uploader
angular-file-uploader p {
  margin-top: 10px;
  margin-bottom: 10px;
}

angular-file-uploader .text-success, angular-file-uploader .textOverflow, angular-file-uploader .sizeC {
  margin: 0;
}

::-ms-clear {
  display: none;
}

/* select 2 custom */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  bottom: 0 !important;
}

.select2-container {
  height: 100%;
}

.select2-selection--single {
  height: 100% !important;
}

.select2-selection__rendered {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-form-field-flex {
  padding: 1px 5px !important;
}

c-jquery-select2 {
  margin-bottom: -3px;
}
//@supports (-moz-appearance:none) {
//  .form-control {
//    padding-bottom: 5px !important;
//  }
//}

.help-block {
  color: #ea5460 !important;
}

body {
  font-family: Roboto, Helvetica, SansSerif;
  overflow: scroll !important;
}

//login
body .page-loaded {
  height: 100vh !important
}

@media (max-width: 992px) {
  .login-content {
    width: 92%;

    app-login {
      width: 100%;
      justify-content: center;
      display: flex;
      min-height: 27vh;
    }
  }
  login-aside div .login-slogan {
    font-size: 1.5rem !important;
  }
}


//jointjs
#kt_content {
  padding: 0;

  & .container {
    //margin-top: 13px;
    overflow-y: hidden;
    padding: 0;
  }
}

#kt_header {
  //display: none;
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 101;
}

.m-page {
  height: 100%;
}

.m-portlet__body {
  margin-top: 63px;
}

//jointjs global
#kt_aside {
  border-right: 1px solid gainsboro;
  //box-shadow: 1px 1px 1px gainsboro;
  background-color: white;
}

#kt_aside_menu.aside-left {
  border-right: 1px solid gainsboro;
  //box-shadow: 1px 1px 1px gainsboro;
  background-color: white;
}


.header-mobile {
  width: 100%;
  position: fixed;
  z-index: 10;
}

//app-layout {
//  height: 100%;
//}

//.ng-star-inserted {
//  height: 100%;
//}
.flex-root {
  height: 100%;
}

.kt_wrapper {
  height: 100%;
}

.kt_content {
  height: 100%;
  margin-top: -1px;
}

#kt_header_mobile {
  box-shadow: none;
}

.container {
  height: 100%;
  padding: 0 10px;
}

.card {
  margin: 15px 20px;
}


//modal autocomplete
.cdk-overlay-container {
  z-index: 9999 !important;
}

div > .table {
  min-width: 100%;
  //width: 0;
}

.noHover {
  pointer-events: none;
}

.top-bar-margin {
  margin-top: $card-margin-top
}
.card-custom {
  //margin-top: 80px;
  margin-top: ($header-height+$subheader-height+$card-margin-top);
}

.card-custom-fit {
  width: 100%;
  margin-left: 0;
  //margin-top: 80px;z-index: 2;
  margin-top: ($header-height+$subheader-height);
}

//GLOBAL RED / mat
.disabled {
  opacity: 0.25 !important;
}

.disabled > span {
  opacity: 0.85 !important;
  color: $gray-400 !important;
}

.disabled-form {
  opacity: 0.65 !important;
  pointer-events: none;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: red;
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: red;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}

.mat-expanded .mat-expansion-indicator::after {
  color: red
}

.mat-expanded .mat-expansion-panel-header-title {
  color: red
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.mat-expansion-panel-header {
  background-color: $gray-100 !important;

  &:hover {
    background-color: $gray-200 !important;
  }
}

.mat-tree-node[aria-expanded="true"] {
  color: red;
  border: dotted #e9dede;
}

.mat-tab-body-wrapper {
  margin-bottom: 50px
}

.mat-tab-body-wrapper {
  height: 100%;
}

.mat-tab-body {
  height: 100%;
}

//sweet service

.sweet-alert h2 {
  margin-top: 28px;
}

.sa-confirm-button-container > button {
  //color: red;
}

.ng-touched::-webkit-input-placeholder {
  color: $gray-500 !important
}

.ng-untouched::-webkit-input-placeholder {
  color: $gray-500 !important
}

//select2
.min-width-select2 {
  min-width: 200px;
  box-sizing: border-box;
}

.select2-results__option {
  overflow: hidden;
  text-overflow: ellipsis;
}

.select2-selection__choice {
  background-color: $gray-300 !important;
  color: $gray-600 !important;

  span {
    color: $gray-600 !important;
  }

  span:hover {
    color: black !important;
  }
}

.example-box-color {
  color: $gray-100 !important;
  span {
    color: $gray-100 !important;
  }

  span:hover {
    color: $gray-400 !important;
  }
}

.example-box {
  background-color: $gray-500 !important;
  color: $gray-100 !important;

  span {
    color: $gray-100 !important;
  }

  span:hover {
    color: $gray-400 !important;
  }
}

.color-box {
  float: left;
  height: 20px;
  width: 20px;
  margin-top: 35px;
  margin-bottom: 15px;
  //border: 1px solid #000000;
  clear: both;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $danger2;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("assets/img/feature-img/elements/redError.svg") !important;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("assets/img/feature-img/elements/greenTick.svg") !important;
}

.valid-feedback {
  color: green !important;
}

//pipeline console
.mat-tab-label-active {
  color: red;

  .mat-tab-label-content .ng-star-inserted {
    filter: invert(34%) sepia(331%) saturate(12752%) hue-rotate(-12deg) brightness(96%) contrast(104%);
  }
}

.remove-border-bottom .mat-tab-header {
  border-bottom: none;
}

.mat-tab-nav-bar:active {
  color: red !important;
}

.mat-ink-bar {
  background-color: red !important;
  display: block;
  height: 1px !important;
}

.red-bg {
  background-color: $danger-light;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}

// Change modal backdrop
.modal-backdrop {
  opacity: 0.2 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $danger2 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $danger2 !important;
}

.ng-trigger {
  //padding: 20px 5px !important;
}


.toggle {
  filter: hue-rotate(
      130deg
  ) brightness(1.1);
}


.pipeline-console .inspector-container .joint-inspector.joint-theme-material {
  cursor: not-allowed;

  .group {
    pointer-events: none;
  }
}

.text-align-center {
  text-align:center;
}

.jumbotron:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  border: 5px solid black;
}

@import '../node_modules/@syncfusion/ej2-spreadsheet/styles/material.css';
