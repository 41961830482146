//
// Buttons
//

// Base
.btn {
	outline: none !important;
	vertical-align: middle;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;

	// Transition
	@include button-custom-transition($transition-link);

	&:focus:not(.btn-shadow):not(.btn-shadow-hover) {
		box-shadow: none !important;
	}

	// Button styles
	&.btn-square {
		border-radius: 0;
	}

	&.btn-pill {
		border-radius: 2rem;
	}

	&.btn-text {
		cursor: text;
	}

	&:not(.btn-text) {
		cursor: pointer;
	}

	&.btn-borderless {
		border-color: transparent;
	}
}

// Icons
.btn {
	// Icons
	i {
		font-size: 1.3rem;
		padding-right: 0.35rem;
		vertical-align: middle;
		line-height: 1;
		display: inline-flex;

		&:after,
		&:before {
			//line-height: 0;
		}
	}

	// Svg icons
	.svg-icon {
		margin-right: 0.5rem;
		@include svg-icon-size(18px);
	}

	// Sizes
	&.btn-xs {
		i {
			font-size: 1rem;
			padding-right: 0.3rem;
		}

		.svg-icon {
			margin-right: 0.3rem;
			@include svg-icon-size(16px);
		}
	}

	&.btn-sm {
		i {
			font-size: 1.3rem;
			padding-right: 0.35rem;
		}

		.svg-icon {
			margin-right: 0.35rem;
			@include svg-icon-size(16px);
		}
	}

	&.btn-lg {
		i {
			font-size: 1.5rem;
			padding-right: 0.75rem;
		}

		.svg-icon {
			margin-right: 0.75rem;
			@include svg-icon-size(20px);
		}
	}

	// Fixed icon button
	&.btn-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		height: $input-height;
		width: $input-height;

		// Sizes
		&.btn-xs {
			height: $btn-icon-size-xs;
			width: $btn-icon-size-xs;
		}

		&.btn-sm {
			height: $input-height-sm;
			width: $input-height-sm;
		}

		&.btn-lg {
			height: $input-height-lg;
			width: $input-height-lg;
		}

		&.btn-circle {
			border-radius: 50%;
		}

		i {
			padding: 0;
			margin: 0;
		}

		[class^="socicon-"],
		[class*=" socicon-"] {
			&:before {
				line-height: 1;
			}
		}

		.svg-icon {
			margin: 0;
			padding: 0;
		}
	}

	// Fixed Height
	&.btn-fixed-height {
		padding-top: 0;
		padding-bottom: 0;
		display: flex;
		align-items: center;

		&.btn {
			height: $input-height;
		}

		&.btn-sm {
			height: $input-height-sm;
		}

		&.btn-lg {
			height: $input-height-lg;
		}
	}
}

//
//// Dropdown Toggle
//.dropdown-toggle {
//	&.nav-link,
//	&.btn {
//		// Arrow icon
//		&:before,
//		&:after {
//			transition: $transition-link;
//			text-align: center;
//			display: inline;
//			border: 0;
//			font-size: 0.6rem;
//			line-height: 0;
//			vertical-align: middle;
//			position: relative;
//		}
//
//		&:after {
//			margin-left: 0.5rem#{'/*rtl:ignore*/'};
//			@include ki(get($ki-types, arrowDown));
//		}
//
//		&:before {
//			margin-right: 0.5rem#{'/*rtl:ignore*/'};
//			@include ki(get($ki-types, arrowBack));
//			display: none;
//		}
//
//		&.dropdown-toggle-split {
//			opacity: 0.9;
//
//			&:after,
//			&:before {
//				margin: 0;
//			}
//		}
//
//		.show > &,
//		&.active,
//		&:focus,
//		&:active,
//		&:hover {
//			&:after,
//			&:before {
//				transition: $transition-link;
//			}
//
//			&.dropdown-toggle-split {
//				opacity: 1 !important;
//			}
//		}
//	}
//}
//
//.btn-group {
//	// Direction
//	&.dropup > .btn.dropdown-toggle {
//		&:after {
//			@include ki(get($ki-types, arrowUp));
//		}
//	}
//
//	&.dropright > .btn.dropdown-toggle {
//		&:after {
//			@include ki(get($ki-types, arrowNext));
//		}
//	}
//
//	&.dropleft > .btn {
//		@include border-top-right-radius($btn-border-radius !important);
//		@include border-bottom-right-radius($btn-border-radius !important);
//
//		&.dropdown-toggle-split {
//			@include border-top-right-radius(0 !important);
//			@include border-bottom-right-radius(0 !important);
//		}
//
//		&.dropdown-toggle {
//			&:after {
//				display: none;
//			}
//
//			&:before {
//				display: inline;
//			}
//		}
//	}
//}
//
//// Clean button
//.btn.btn-clean {
//	$btn-background: transparent;
//	$btn-border: transparent;
//	$btn-color: $gray-500;
//	$btn-icon: $gray-500;
//	$btn-box-shadow: null;
//
//	$btn-hover-background: $gray-100;
//	$btn-hover-border: transparent;
//	$btn-hover-color: $primary;
//	$btn-hover-icon: $primary;
//	$btn-hover-box-shadow: null;
//
//	$btn-active-background: $btn-hover-background;
//	$btn-active-border: $btn-hover-border;
//	$btn-active-color: $btn-hover-color;
//	$btn-active-icon: $btn-hover-icon;
//	$btn-active-box-shadow: $btn-hover-box-shadow;
//
//	@include button-custom-variant(
//		$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//		$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//		$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//	);
//}
//
//// Default button
//.btn.btn-default {
//	$btn-background: $gray-100;
//	$btn-border: $gray-100;
//	$btn-color: $gray-500;
//	$btn-icon: $gray-500;
//	$btn-box-shadow: null;
//
//	$btn-hover-background: $gray-100;
//	$btn-hover-border: $gray-100;
//	$btn-hover-color: $primary;
//	$btn-hover-icon: $primary;
//	$btn-hover-box-shadow: null;
//
//	$btn-active-background: $btn-hover-background;
//	$btn-active-border: $btn-hover-border;
//	$btn-active-color: $btn-hover-color;
//	$btn-active-icon: $btn-hover-icon;
//	$btn-active-box-shadow: $btn-hover-box-shadow;
//
//	@include button-custom-variant(
//		$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//		$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//		$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//	);
//}
//
//// Theme colors
//@each $name, $value in $theme-colors {
//	//	@include button-custom-variant(
//	//		$background, $border, $color, $icon, $box-shadow,
//	//		$hover-background, $hover-border, $hover-color, $hover-icon, $hover-box-shadow,
//	//		$active-background, $active-border, $active-color, $active-icon, $active-box-shadow
//	//  );
//
//	// Default Style
//	.btn.btn-#{$name} {
//		$btn-background: $value;
//		$btn-border: $value;
//		$btn-color: theme-inverse-color($name);
//		$btn-icon: theme-inverse-color($name);
//		$btn-box-shadow: null;
//
//		$btn-hover-background: theme-hover-color($name);
//		$btn-hover-border: theme-hover-color($name);
//		$btn-hover-color: theme-inverse-color($name);
//		$btn-hover-icon: theme-inverse-color($name);
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Outline Style
//	.btn.btn-outline-#{$name} {
//		$btn-background: transparent;
//		$btn-border: $value;
//		$btn-color: theme-outline-inverse-color($name);
//		$btn-icon: theme-outline-inverse-color($name);
//		$btn-box-shadow: null;
//
//		$btn-hover-background: $value;
//		$btn-hover-border: $value;
//		$btn-hover-color: theme-inverse-color($name);
//		$btn-hover-icon: theme-inverse-color($name);
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Light Style
//	.btn.btn-light-#{$name} {
//		$btn-background: theme-light-color($name);
//		$btn-border: transparent;
//		$btn-color: $value;
//		$btn-icon: $value;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: $value;
//		$btn-hover-border: transparent;
//		$btn-hover-color: theme-inverse-color($name);
//		$btn-hover-icon: theme-inverse-color($name);
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Transparent Style
//	.btn.btn-transparent-#{$name} {
//		$btn-background: rgba($value, 0.1);
//		$btn-border: transparent;
//		$btn-color: $value;
//		$btn-icon: $value;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: $value;
//		$btn-hover-border: transparent;
//		$btn-hover-color: theme-inverse-color($name);
//		$btn-hover-icon: theme-inverse-color($name);
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Transparent Hover Style
//	.btn.btn-hover-transparent-#{$name} {
//		$btn-background: transparent;
//		$btn-border: transparent;
//		$btn-color: $value;
//		$btn-icon: $value;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: rgba($value, 0.1);
//		$btn-hover-border: transparent;
//		$btn-hover-color: $value;
//		$btn-hover-icon: $value;
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Light Hover Style
//	.btn.btn-hover-light-#{$name} {
//		$btn-background: null;
//		$btn-border: null;
//		$btn-color: null;
//		$btn-icon: null;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: theme-light-color($name);
//		$btn-hover-border: transparent;
//		$btn-hover-color: $value;
//		$btn-hover-icon: $value;
//		$btn-hover-box-shadow: null;
//
//
//    $btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//
//		// Integrate btn-clean
//		&.btn-clean {
//			border: 0 !important;
//		}
//	}
//
//	// Link Style
//	.btn.btn-link-#{$name} {
//		border: 0;
//		padding: 0 !important;
//		border-radius: 0 !important;
//
//		$btn-background: transparent !important;
//		$btn-border: transparent !important;
//		$btn-color: $value;
//		$btn-icon: $value;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: $btn-background;
//		$btn-hover-border: $btn-border;
//		$btn-hover-color: theme-hover-color($name);
//		$btn-hover-icon: theme-hover-color($name);
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Hover Style
//	.btn.btn-hover-#{$name} {
//		$btn-background: null;
//		$btn-border: null;
//		$btn-color: null;
//		$btn-icon: null;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: $value !important;
//		$btn-hover-border: $value !important;
//		$btn-hover-color: theme-inverse-color($name) !important;
//		$btn-hover-icon: theme-inverse-color($name) !important;
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//
//		// Integrate btn-clean
//		&.btn-clean {
//			border: 0 !important;
//		}
//	}
//
//	// Hover Background Style
//	.btn.btn-hover-bg-#{$name} {
//		$btn-background: null;
//		$btn-border: null;
//		$btn-color: null;
//		$btn-icon: null;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: $value !important;
//		$btn-hover-border: $value !important;
//		$btn-hover-color: null;
//		$btn-hover-icon: null;
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Background Style
//	.btn.btn-bg-#{$name} {
//		$btn-background: $value;
//		$btn-border: $value;
//		$btn-color: null;
//		$btn-icon: null;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: null;
//		$btn-hover-border: null;
//		$btn-hover-color: null;
//		$btn-hover-icon: null;
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Shadow Style
//	.btn.btn-#{$name}.btn-shadow {
//		$btn-background: null;
//		$btn-border: null;
//		$btn-color: null;
//		$btn-icon: null;
//		$btn-box-shadow: 0px 9px 16px 0px rgba(theme-shadow-color($name), 0.25) !important;
//
//		$btn-hover-background: null;
//		$btn-hover-border: null;
//		$btn-hover-color: null;
//		$btn-hover-icon: null;
//		$btn-hover-box-shadow: null;
//		$btn-hover-box-shadow: 0px 9px 16px 0px rgba(theme-shadow-color($name), 0.3) !important;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Hover Shadow Style
//	.btn.btn-#{$name}.btn-shadow-hover {
//		$btn-background: null;
//		$btn-border: null;
//		$btn-color: null;
//		$btn-icon: null;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: null;
//		$btn-hover-border: null;
//		$btn-hover-color: null;
//		$btn-hover-icon: null;
//		$btn-hover-box-shadow: 0px 9px 16px 0px rgba(theme-shadow-color($name), 0.3) !important;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//}
//
//// Theme text colors
//@each $name, $value in $theme-text-colors {
//	//	@include button-custom-variant(
//	//		$background, $border, $color, $icon, $box-shadow,
//	//		$hover-background, $hover-border, $hover-color, $hover-icon, $hover-box-shadow,
//	//		$active-background, $active-border, $active-color, $active-icon, $active-box-shadow
//	//  );
//
//	// Text Style
//	.btn.btn-text-#{$name} {
//		$btn-background: null;
//		$btn-border: null;
//		$btn-color: $value;
//		$btn-icon: null;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: null;
//		$btn-hover-border: null;
//		$btn-hover-color: null;
//		$btn-hover-icon: null;
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Icon Style
//	.btn.btn-icon-#{$name} {
//		$btn-background: null;
//		$btn-border: null;
//		$btn-color: null;
//		$btn-icon: $value;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: null;
//		$btn-hover-border: null;
//		$btn-hover-color: null;
//		$btn-hover-icon: null;
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Hover Text Style
//	.btn.btn-hover-text-#{$name} {
//		$btn-background: null;
//		$btn-border: null;
//		$btn-color: null;
//		$btn-icon: null;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: null;
//		$btn-hover-border: null;
//		$btn-hover-color: $value !important;
//		$btn-hover-icon: null;
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//
//	// Hover Icon Style
//	.btn.btn-hover-icon-#{$name} {
//		$btn-background: null;
//		$btn-border: null;
//		$btn-color: null;
//		$btn-icon: null;
//		$btn-box-shadow: null;
//
//		$btn-hover-background: null;
//		$btn-hover-border: null;
//		$btn-hover-color: null;
//		$btn-hover-icon: $value !important;
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//	}
//}
//
//// Social buttons
//@each $name, $value in $social-colors {
//    .btn.btn-#{$name} {
//		$btn-background: get($value, base);
//		$btn-border: get($value, base);
//		$btn-color: get($value, inverse);
//		$btn-icon: get($value, inverse);
//		$btn-box-shadow: null;
//
//		$btn-hover-background: get($value, hover);
//		$btn-hover-border: get($value, hover);
//		$btn-hover-color: get($value, inverse);
//		$btn-hover-icon: get($value, inverse);
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//    }
//
//	.btn.btn-light-#{$name} {
//		$btn-background: get($value, light);
//		$btn-border: transparent;
//		$btn-color: get($value, base);
//		$btn-icon: get($value, base);
//		$btn-box-shadow: null;
//
//		$btn-hover-background: get($value, base);
//		$btn-hover-border: transparent;
//		$btn-hover-color: get($value, inverse);
//		$btn-hover-icon: get($value, inverse);
//		$btn-hover-box-shadow: null;
//
//		$btn-active-background: $btn-hover-background;
//		$btn-active-border: $btn-hover-border;
//		$btn-active-color: $btn-hover-color;
//		$btn-active-icon: $btn-hover-icon;
//		$btn-active-box-shadow: $btn-hover-box-shadow;
//
//		@include button-custom-variant(
//			$btn-background, $btn-border, $btn-color, $btn-icon, $btn-box-shadow,
//			$btn-hover-background, $btn-hover-border, $btn-hover-color, $btn-hover-icon, $btn-hover-box-shadow,
//			$btn-active-background, $btn-active-border, $btn-active-color, $btn-active-icon, $btn-active-box-shadow
//		);
//    }
//}
